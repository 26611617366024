import { Link } from 'gatsby';
import React from 'react';

const Card = ({ text, h3, to, linktitle }) => {
  return <div>
      <div className="card">
        <div className="center">
          <div className="card-top">
            <h3>
             {h3}
            </h3>
          </div>
          
          <div className=" card-content">
             <p>{text}</p> 
          
          <Link className='btn-sideNav' to={to} >{linktitle}</Link>
          </div>
  </div>
  </div>
  </div>
};

export default Card;
