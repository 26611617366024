import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaDrum } from 'react-icons/fa';
import { AiOutlineTeam } from 'react-icons/ai';
import { RiTapeLine } from 'react-icons/ri';

const CarouselComp = () => {
  return (
    <Carousel fade variant="dark" interval="8000">
      <Carousel.Item>
        <StaticImage
          className="carousel-img"
          src="../images/carousel_drumset_dark.jpg"
          alt="Schlagzeug Bild"
          fit="cover"
          placeholder="blurred"
          loading="eager"
          aspectRatio={16 / 9}
        />
        <Carousel.Caption>
          <h2 className="h2">
            <FaDrum /> Unterricht
          </h2>
          <h3 className="h3">
            Anfänger oder Profi? <br></br>
            <br></br>Ich bringe dein Trommeln auf ein neues Level!
          </h3>

          <Link to="/unterricht">
            {' '}
            <Button>
              Probestunde <MdKeyboardArrowRight className="arrow-icon" />
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          className="carousel-img"
          src="../images/carousel_teamtraining_dark.jpg"
          alt="Teamtraining"
          fit="cover"
          placeholder="blurred"
          loading="eager"
          aspectRatio={16 / 9}
        />
        <Carousel.Caption>
          <h2 className="h2">
            <AiOutlineTeam /> Teamtraining
          </h2>
          <h3 className="h3">
            Du kannst gar nicht trommeln?<br></br>
            <br></br> Ich beweise dir das Gegenteil!<br></br>
          </h3>

          <Link to="/teamtraining">
            <Button>
              probiere es aus <MdKeyboardArrowRight className="arrow-icon" />
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          className="carousel-img"
          src="../images/carousel_recording_dark.jpg"
          alt="Recording"
          fit="cover"
          placeholder="blurred"
          loading="eager"
          aspectRatio={16 / 9}
        />
        <Carousel.Caption>
          <h2 className="h2">
            <RiTapeLine /> Recording / Produktion
          </h2>
          <h3 className="h3">Musik für die Ewigkeit </h3>

          <Link to="/recording">
            <Button>
              zum Angebot <MdKeyboardArrowRight className="arrow-icon" />
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComp;
